import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  async function login(email, password) {
    try {
      // Ensure you have REACT_APP_API_BASE_URL set in your .env file pointing to your backend
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/login`, {
        email,
        password
      }, { withCredentials: true }); // Include withCredentials to send cookies
      // Assuming your backend sends back an access token and possibly user data on successful login
      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token); // Store the token
        // Adjust based on what your backend actually returns
        setCurrentUser(response.data.user); // Update current user state
        return response.data; // Return data for any post-login actions
      }
    } catch (error) {
      alert(error.response ? error.response.data.message : "Login failed. Please try again.");
      return null; // Return null in case of error for any error handling
    }
  }
  
  async function signup(username, email, password) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/signup`, {
            username,
            email,
            password,
        }, { withCredentials: true }); // Include withCredentials here as well if your signup process involves authentication
        console.log(response.data); // Handle response
    } catch (error) {
        console.error(error); // Handle error
    }
  }

  async function logout() {
    try {
      // Make sure to include withCredentials if your backend requires a cookie to logout
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/logout`, {}, { withCredentials: true });
      setCurrentUser(null); // Clear user state
    } catch (error) {
      console.error(error); // Handle error
    }
  }

  useEffect(() => {
    // Initialization logic here, e.g., checking for an existing token
    setLoading(false);
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
