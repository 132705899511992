import mixpanel from 'mixpanel-browser';

let sessionStartTime = null;

export const startSession = () => {
  sessionStartTime = new Date().getTime();
  mixpanel.time_event('Session');
};

export const endSession = () => {
  if (sessionStartTime) {
    mixpanel.track('Session');
    sessionStartTime = null;
  }
};

export const trackEvent = (eventName, properties = {}) => {
  mixpanel.track(eventName, properties);
};