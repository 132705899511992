import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import mixpanel from 'mixpanel-browser';
import { startSession, endSession, trackEvent } from './mixpanelHelpers';
import initHotjar from './components/hotjar';

const MessageSubmit = React.lazy(() => import('./components/MessageSubmit'));
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const Login = React.lazy(() => import('./components/Login'));
const Signup = React.lazy(() => import('./components/Signup'));
const Settings = React.lazy(() => import('./components/Settings'));
const MyLink = React.lazy(() => import('./components/MyLink'));
const Hero = React.lazy(() => import('./components/Hero'));
const OAuthCallback = React.lazy(() => import('./components/OAuthCallback'));
const Error = React.lazy(() => import('./components/error'));
const PrivacyPolicy = React.lazy(() => import('./components/privacy'));
const ResetPassword = React.lazy(() => import('./components/ResetPassword'));


mixpanel.init('fb5ec4aadd3ced57180d58cfb8fe7c47');

function RouteChangeTracker() {
  const location = useLocation();

  useEffect(() => {
    trackEvent('Page View', { path: location.pathname });
  }, [location]);

  return null;
}

function App() {
  useEffect(() => {
    startSession();

    const handleUnload = () => {
      endSession();
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
      endSession();
    };
  }, []);

  useEffect(() => {
    initHotjar();
  }, []);

  return (
    <AuthProvider>
      <Router>
        <RouteChangeTracker />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/dashboard/:username" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/:username" element={<MessageSubmit />} />
            <Route path="/" element={<Hero />} />
            <Route path="/oauth/callback" element={<OAuthCallback />} />
            <Route path="/error" element={<Error />} />
            <Route path="/privacy" element={<PrivacyPolicy />} /> {/* New Route */}
            <Route path="/reset-password/:token" element={<ResetPassword />} /> {/* Add this new route */}
          </Routes>
        </Suspense>
      </Router>
    </AuthProvider>
  );
}

export default App;